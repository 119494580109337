import { stateList } from "../../../../../../Components/GST/stateData"
import { getDiscountedPrice, getPriceOnRateAndTax, getRateOnPrice } from "../../../../../General/DiscountCals"
import { getFormatedDate, ts36ToTimestamp } from "../../../../../General/GetDateTime"
import { iNRWords } from "../../../../../General/numberToWords"
import { numToFixed, numToIfFixed2 } from "../../../../../General/numManipulation"
import { objKeyToArr } from "../../../../../General/objectManipulation"
import { splitIntoLines, writeTextForPDF } from "../../../PDFFunctions"
import { A5PDFInvoice1 } from "../A5PDF/A5PDFInvoice1"
import { invoicePageFooter, invoicePageHeader, invoicePageWaterMark } from "../PDFInvoiceHelperFunc"

const itemsSizeWithFooter = {
    single: 16,
    mid: 28,
    last: 16,
    namesize: 15,
}

const itemsSizeWithOutFooter = {
    single: 20,
    mid: 28,
    last: 20,
    namesize: 15,
}

const itemsSizeNonGST = {
    single: 16,
    mid: 16,
    last: 15,
    namesize: 25,
}

const invoiceHeader = ({ invoiceData, businessData, businessLogo, isgst = false }) => {

    const invoiceBusinessDataPDF = () => {
        const { invoicegstin, invoicetype } = invoiceData
        // const isgst = invoicetype === 'gstinvoice'
        const { gSTData = {} } = businessData
        const { businessname, address = '', mobile = '', email = '' } = (gSTData?.[invoicegstin] ?? {})
        const addressWithTitle = address || businessData.businessaddress || ''
        const addresssplit = (!!addressWithTitle) ? (addressWithTitle + '').split('\n') : []
        const addresssArr = (addresssplit.length) ? (((addresssplit.length > 2) || ((addressWithTitle + '').length > 70)) ? splitIntoLines((addressWithTitle + '').replaceAll('\n', ' '), 70) : addresssplit) : []
        const newaddress = addresssArr.map((line, ind) => [{}, writeTextForPDF(line), {}])
        const newmobile = mobile || businessData.businessphone || ''

        const toprowspan = 2 - (isgst ? -1 : 0) - (-newaddress.length)

        const logo = businessLogo ? {
            image: businessLogo,
            width: 50,
            height: 50,
            // absolutePosition: { x: 480, y: 160 },
            alignment: 'left',
            rowSpan: toprowspan,
        } : {}

        const businessDataTable = {
            table: {
                widths: ['auto', '*', 'auto'],
                body: [
                    [logo, writeTextForPDF(businessname ?? businessData.businessname ?? '', { fontSize: 14, bold: true }), { qr: invoiceData.invoiceid, fit: 70, rowSpan: toprowspan }],
                    ...newaddress,
                    [{}, {
                        text: `${newmobile ? 'Mobile : ' + newmobile : ''}${email ? `${newmobile ? ' | ' : ''}Email : ` + email : ''}`,
                    }, {}],
                    ...(isgst ? [[{}, {
                        text: 'GSTIN : ' + invoicegstin, bold: true
                    }, {}]] : [])
                ],
            },
            layout: {
                hLineWidth: function (i, node) {
                    return ((i === 0) || (i == node.table.body.length)) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                    if (i === 1) return 0
                    return 1;
                }
            }
        }

        return {
            ...businessDataTable,
            alignment: 'center',
        }
    }

    const invoiceDataPDF = () => {
        const { invoicenumber = '', timestamp = '', invoiceOrder } = invoiceData

        return {
            table: {
                widths: ['*'],
                body: [
                    [{
                        columns: [
                            { text: (isgst ? 'Invoice No : ' : 'No : ') + invoicenumber, bold: true },
                            { text: 'Date : ' + getFormatedDate('dd-mm-yyyy', timestamp) + '\t \t', alignment: 'right', bold: true },
                        ]
                    }],
                    ...((!!invoiceOrder?.isorder && (!!invoiceOrder?.expdelivery || !!invoiceOrder?.deliverynote)) ? [[{
                        columns: [
                            ...(!!invoiceOrder?.expdelivery ? [{ text: 'Delivery Date : ' + getFormatedDate('dd-mm-yyyy', ts36ToTimestamp(invoiceOrder.expdelivery)), alignment: 'left', bold: true }] : []),
                            ...(!!invoiceOrder?.deliverynote ? [{ text: invoiceOrder.deliverynote, alignment: 'right' }] : []),
                        ]
                    }]] : [])
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return (i === 0) ? 0 : 1;
                },
            }
        }
    }

    const invoiceCustomerDataPDF = () => {
        const { invoiceCustomerData = {}, invoicegstin = '', customerAdditionalData = {} } = invoiceData
        const { customername, customermobile, customergst, customeraddress, } = invoiceCustomerData
        const customeraddressWithTitle = 'Address: ' + customeraddress
        const customeraddresssplit = (!!customeraddressWithTitle) ? (customeraddressWithTitle + '').split('\n') : ['Address: ']
        const newcustaddresssArr = (customeraddresssplit.length) ? (((customeraddresssplit.length > 3) || ((customeraddressWithTitle + '').length > 80)) ? splitIntoLines((customeraddressWithTitle + '').replaceAll('\n', ' '), 80) : customeraddresssplit) : []
        const newcustomeraddress = newcustaddresssArr.map((line, ind) => [writeTextForPDF(line)])
        const emptylineslen = newcustomeraddress.length ? (3 - newcustomeraddress.length) : 2
        const emptylines = Array.from({ length: (emptylineslen) }, () => '').map(() => [' '])

        return {
            table: {
                widths: ['*'],
                body: [
                    [{ text: 'Customer Details: ', fillColor: 'lightblue' }],
                    [writeTextForPDF('Name: ' + (customername || ''), { fontSize: 11, bold: true })],
                    ...newcustomeraddress,
                    // ...emptylines,
                    ...((isgst && customergst) ? [[{ text: `GSTIN : + ${customergst}     | State Code : ${(invoicegstin + '').substring(0, 2)} (${stateList[(invoicegstin + '').substring(0, 2)]})` }]] : [[' ']]),
                    [{ text: 'Phone No: ' + (customermobile || ' '), bold: true, fontSize: 11 }],
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return 0;
                },
            }
        }
    }

    return {
        stack: [
            invoiceBusinessDataPDF(),
            invoiceDataPDF(),
            invoiceCustomerDataPDF(),
        ]
    }
}

// const opticalDataPDFContent = ({ opticalData }) => {
//     return [
//         {
//             table: {
//                 // widths: [30, 40, 40, 40, 30, 40, 40, 40],
//                 widths: ['*', '*', '*', '*'],
//                 body: [
//                     [...['RE', 'SPH', 'CYL', 'AXIS'].map(item => ({ text: item, bold: true, fillColor: 'lightblue' }))],
//                     [{ text: 'DV', bold: true, fillColor: 'lightblue' }, opticalData.re_dv.sph, opticalData.re_dv.cyl, opticalData.re_dv.axis,],
//                     [{ text: 'NV', bold: true, fillColor: 'lightblue' }, opticalData.re_nv.sph, opticalData.re_nv.cyl, opticalData.re_nv.axis,],
//                 ]
//             },
//             alignment: 'center',
//             fontSize: 10,
//         },
//         {
//             table: {
//                 // widths: [30, 40, 40, 40, 30, 40, 40, 40],
//                 widths: ['*', '*', '*', '*'],
//                 body: [
//                     [...['LE', 'SPH', 'CYL', 'AXIS'].map(item => ({ text: item, bold: true, fillColor: 'lightblue' }))],
//                     [{ text: 'DV', bold: true, fillColor: 'lightblue' }, opticalData.le_dv.sph, opticalData.le_dv.cyl, opticalData.le_dv.axis],
//                     [{ text: 'NV', bold: true, fillColor: 'lightblue' }, opticalData.le_nv.sph, opticalData.le_nv.cyl, opticalData.le_nv.axis],
//                 ]
//             },
//             alignment: 'center',
//             fontSize: 10,
//         }
//     ]
// }

const opticalDataPDFContent = ({ opticalData }) => {
    return {
        table: {
            // widths: [30, 40, 40, 40, 30, 40, 40, 40],
            widths: [20, '*', '*', '*', 20, '*', '*', '*'],
            body: [
                [...['RE', 'SPH', 'CYL', 'AXIS', 'LE', 'SPH', 'CYL', 'AXIS'].map(item => ({ text: item, bold: true, fillColor: 'lightblue', margin: [0, 2, 0, 2] }))],
                [{ text: 'DV', bold: true, fillColor: 'lightblue', margin: [0, 2, 0, 2] }, ...['sph', 'cyl', 'axis'].map(field => ({ text: opticalData.re_dv[field], margin: [0, 3, 0, 3] })), { text: 'DV', bold: true, fillColor: 'lightblue', margin: [0, 2, 0, 2] }, ...['sph', 'cyl', 'axis'].map(field => ({ text: opticalData.le_dv[field], margin: [0, 3, 0, 3] }))],
                [{ text: 'NV', bold: true, fillColor: 'lightblue', margin: [0, 2, 0, 2] }, ...['sph', 'cyl', 'axis'].map(field => ({ text: opticalData.re_nv[field], margin: [0, 3, 0, 3] })), { text: 'NV', bold: true, fillColor: 'lightblue', margin: [0, 2, 0, 2] }, ...['sph', 'cyl', 'axis'].map(field => ({ text: opticalData.le_nv[field], margin: [0, 3, 0, 3] }))],
            ]
        },
        alignment: 'center',
        fontSize: 9,
    }
}

const getPriceOnItem = (item) => {
    const { gstrate, cessrate, quantity, price, discount } = item
    const totaltaxrate = numToFixed(gstrate, cessrate)
    const rate = getDiscountedPrice(getRateOnPrice(getDiscountedPrice(price, discount), totaltaxrate))
    const total = getPriceOnRateAndTax(rate * quantity, numToIfFixed2(gstrate, cessrate))
    return total
}

const itemDataPDFContent = ({ lensItem, frameItem }) => {
    return {
        table: {
            widths: ['*'],
            body: [
                [
                    {
                        columns: [
                            { text: 'Frame\nLens', width: 30 },
                            { text: ':\n:', width: 10, alignment: 'center' },
                            { text: `${frameItem?.name ?? ''} \n${lensItem.name}`, alignment: 'left', width: '*' },
                            { text: `${frameItem?.price ? getPriceOnItem(frameItem) : ''}\n${getPriceOnItem(lensItem)}`, alignment: 'right', width: 'auto', bold: true },
                        ],
                    }
                ],
            ]
        }
    }
}

const paymentSummary = ({ invoiceData, paymentTypes }) => {
    const { invoicesaletype, invoicePayment, invoicetotal, invoiceAdditionalData } = invoiceData
    const duedate = invoiceAdditionalData?.track?.duedate

    const activePyament = objKeyToArr(paymentTypes).filter(key => paymentTypes[key].isenabled)
    let paymentTypeKey = activePyament.filter(key => paymentTypes[key].primary && !!paymentTypes[key].upi)?.[0]
    if (!paymentTypeKey)
        paymentTypeKey = objKeyToArr(activePyament).filter(key => !!paymentTypes[key].upi)?.[0]
    const paymentType = paymentTypes?.[paymentTypeKey]
    const invoicebalance = (invoicesaletype === 'credit' ? invoicetotal - invoicePayment.paid : 0).toFixed(2)

    return {
        table: {
            widths: ['auto', '*'],
            body: [
                [
                    // { qr: paymentType?.upi ? `upi://pay?pa=${paymentType.upi}&cu=INR` : 'NOTFOUND', fit: 70, rowSpan: 4 },
                    { stack: [{ qr: paymentType?.upi ? `upi://pay?pa=${paymentType.upi}&cu=INR` : 'NOTFOUND', fit: 70 }, { text: paymentType?.upi ? 'Scan With Any UPI App' : '', alignment: 'center' }], rowSpan: 4 },
                    {
                        stack: [
                            ...(invoicePayment.other ? [{ columns: [{ text: 'Other  :', alignment: 'right' }, { text: '₹' + invoicePayment.other, alignment: 'right' }] }] : []),
                            ...(invoicePayment.shipping ? [{ columns: [{ text: 'Shipping  :', alignment: 'right' }, { text: '₹' + invoicePayment.shipping, alignment: 'right' }] }] : []),
                            { columns: [{ text: 'Total  :', alignment: 'right', fontSize: 11 }, { text: '₹' + Number(invoiceData.invoicetotal - (-invoicePayment.discount)).toFixed(2), alignment: 'right', bold: true, fontSize: 11 }] },
                            { columns: [{ text: 'Discount  :', alignment: 'right', fontSize: 11 }, { text: '(-) ₹' + invoicePayment.discount, alignment: 'right', bold: true, fontSize: 11 }] },
                        ]
                    },
                ],
                [{}, {
                    stack: [
                        { columns: [{ text: 'Total Amount  :', fontSize: 15, alignment: 'right' }, { text: '₹' + invoiceData.invoicetotal, alignment: 'right', bold: true, fontSize: 15 }] },
                        { text: iNRWords(invoiceData.invoicetotal), alignment: 'right', fontSize: 10, bold: true },
                    ]
                }],
                [{}, { columns: [{ text: 'Advance Payment  :', alignment: 'right', fontSize: 11 }, { text: '₹' + ((invoiceData.invoicesaletype === 'credit') ? invoicePayment.paid : invoicetotal), alignment: 'right', bold: true, fontSize: 11 }] }],
                [{}, { columns: [{ text: `Balance Due  :${(!!invoicebalance && duedate) ? '\nDue Date  :' : ''}`, alignment: 'right', fontSize: 11 }, { text: `₹${invoicebalance}${(!!invoicebalance && duedate) ? `\n${getFormatedDate('dd-mm-yyyy', ts36ToTimestamp(duedate))}` : ''}`, alignment: 'right', bold: true, fontSize: 11 }] }],

                //total savings
                ...(invoicePayment.discount ? [[{ text: `Total Savings : ₹${invoicePayment.discount}`, fontSize: 11, colSpan: 2, bold: true, alignment: 'center' }, {}]] : []),

                //invoice remarks
                [{ text: [{ text: `Remarks: `, bold: true, fillColor: 'lightblue' }, invoiceAdditionalData?.remarks ? invoiceAdditionalData.remarks : ''], fontSize: 9, colSpan: 2 }, {}],
            ]
        }
    }
}

const invoiceFooter = ({ invoiceData, receiptSettings, businessData }) => {
    const { invoicegstin } = invoiceData
    const { gSTData = {} } = businessData
    const { businessname, address = '', mobile = '', email = '' } = (gSTData?.[invoicegstin] ?? {})

    return {
        table: {
            widths: ['*', '*'],
            body: [
                [
                    {
                        stack: [
                            { text: 'Terms & Conditions', bold: true },
                            { text: receiptSettings?.termsandconditions ?? '', fontSize: 10 }
                        ]
                    },
                    {
                        stack: [
                            writeTextForPDF('for ' + (businessname ?? businessData.businessname ?? '') + '\n\n\n\n\n'),
                            { text: 'Authorised Signature' }
                        ]
                    }
                ]
            ]
        }
    }
}

const formatContent = ({ invoiceData, businessData, businessLogo, defaultUnits, receiptSettings, paymentTypes }, invoiceFormatSetting) => {
    const lensItem = invoiceData.softInvoiceItems.filter(item => !!item?.opticalData?.isopticaldata)[0]
    const frameItem = invoiceData.softInvoiceItems.filter(item => item.saleid !== lensItem?.saleid)?.[0] ?? {}

    return [
        invoiceHeader({ invoiceData, businessData, businessLogo, isgst: invoiceData.invoicetype === 'gstinvoice' }),
        opticalDataPDFContent({ opticalData: lensItem.opticalData }),
        itemDataPDFContent({ lensItem, frameItem }),
        paymentSummary({ invoiceData, paymentTypes }),
        invoiceFooter({ invoiceData, receiptSettings, businessData }),
    ]
}

export const OpticalA5Format1 = ({
    invoiceData,
    businessData,
    coll = 'sales',
    receiptSettings,
    defaultUnits,
    businessLogo,
    invoiceFormatSetting,
    paymentTypes,
}) => {

    const { softInvoiceItems } = invoiceData
    const isopticalDataFound = softInvoiceItems.filter(item => !!item?.opticalData?.isopticaldata)

    if ((isopticalDataFound.length < 1) || (softInvoiceItems.length > 2) || (isopticalDataFound.length > 1))
        return A5PDFInvoice1({ invoiceData, businessData, coll, receiptSettings, defaultUnits, businessLogo, invoiceFormatSetting, paymentTypes })
    // if (isopticalDataFound.length > 1)
    //     return A5PDFInvoice1({ invoiceData, businessData, coll, receiptSettings, defaultUnits, businessLogo, invoiceFormatSetting, paymentTypes })

    const forcegstformat = invoiceFormatSetting?.alwaysgst ?? false
    const invoicetype = (invoiceData.invoicetype === 'quotation') ? (forcegstformat ? 'estimation' : 'quotation') : invoiceData.invoicetype

    let dd = {
        footer: function (currentPage, pageCount) {
            return invoicePageFooter({ currentPage, pageCount, receiptSettings })
        },
        header: function (currentPage, pageCount, pageSize) {
            return invoicePageHeader({ invoicetype, coll, title: invoiceFormatSetting.title })
        },
        watermark: invoicePageWaterMark({ receiptSettings }),
        content: [...formatContent({ invoiceData, businessData, businessLogo, defaultUnits, receiptSettings, paymentTypes }, invoiceFormatSetting, paymentTypes)],
        defaultStyle: {
            fontSize: 8,
            // margin: 1,
        },
        styles: {
            tableStyle: {
                fontSize: 11,
                bold: true,
            }
        }
    }

    return dd
}
